import CryptoJS from 'crypto-js';
import store from '@/store';
import { HOMETYPES } from '@/utils/utils'

const AES_KEY = '6a488beca905415b';
export const PWA_TOKEN = '66f625994c344299bab483bef0a9cac8';

export const ASSIGN_VIEW = {
    LOCK: 'lock'
}

export function getTakeoutFriendUrl(ask) {
    if (ask === 5)
        return "/api/clerk/wash_takeout/list/notify"
    else if (store.getters.pageHome === HOMETYPES.LOCKER)
        return "/api/box/takeout/friend"
    else if (store.getters.pageHome === HOMETYPES.WASH)
        return "/api/box/7/takeout/friend"
    else return
}

export function getEncryptedToken(token=null) {
    let actualToken = token || store.getters.user.token;
    let dateStr = new Date().toISOString()
    return CryptoJS.AES.encrypt(actualToken + '@' + dateStr, AES_KEY).toString();
}

export function getAxiosConfig(token=null) {
    return {
        headers: { Authorization: getEncryptedToken(token) }
    };
}

export function encrypt(message) {
    return CryptoJS.AES.encrypt(message, AES_KEY).toString();
}

export function decrypt(message) {
    if (message)
        return CryptoJS.AES.decrypt(message, AES_KEY).toString(CryptoJS.enc.Utf8);
    else
        return 0
}

export function parseBoxQR(value, isUrl=true) {
    let params
    if (isUrl) {
        let url = new URL(value);
        params = url.searchParams.get('q');
    } else params = decodeURIComponent(value)
    return JSON.parse(decrypt(params));
}

import i18n from '@/i18n'
function uriMark() {
    let uri = location.href
    let uri_mark = (uri.indexOf('storehome') !== -1) || (uri.indexOf('washhome') !== -1)
    if (uri_mark !== true) {
        uri_mark = (uri[uri.length-3]+uri[uri.length-2]+uri[uri.length-1]).indexOf(i18n.locale.toString()) !== -1
    }
    if (uri_mark) return true
    else if (uri.indexOf('menu') !== -1) return 'menu'
    else return false
}

export function localUser() {
    let local = localStorage.getItem('user')
    let uu = decrypt(local)
    if (parseInt(uu)) {
        let mark = uriMark()
        let agent = navigator.userAgent
        console.log('[uri_mark] - '+mark)
        if (mark == true) return true
        else if ((mark === 'menu') && ((agent.indexOf('iPhone') !== -1) || (agent.indexOf('iPad') !== -1))) return true
        else return false
    }
}